<template>
  <b-overlay :show="isLoading" rounded="lg" opacity="0.6" spinner-variant="primary">
    <template #overlay>
      <div class="d-flex align-items-center">
        <b-spinner small type="grow" variant="secondary"></b-spinner>
        <b-spinner type="grow" variant="dark"></b-spinner>
        <b-spinner small type="grow" variant="secondary"></b-spinner>
      </div>
    </template>
    <router-link class="btn btn-sm btn-dark  "  size="sm"  :to="{ path: '/audio'}">
      <em class="fa fa-backward btn-sm btn-dark "></em> Back
    </router-link>
    <div class="row mt-4 create-new">
      <div class="col-md-12">
        <b-form @submit="onSubmit" @reset="reset" v-if="show">
          <div class="card">
            <div class="bg-success p-3">
              <h5 class="card-title mb-0 font-weight-bold">Create New AudioBook</h5>
            </div>
            <div class="card-body">
              <div class="row">
                <b-col lg="6" class="mt-4">
                  <b-card class="bg-info text-dark">(*) Kolom berlabel bintang wajib diisi.</b-card>
                </b-col>
              </div>
              
              <div class="row">
                <div class="col-md-6">
                  Parents Items (*)
                  <b-form-checkbox switch size="lg"
                    v-model="isParents"
                    @change="isParentsChange()"
                    ></b-form-checkbox>
                  </div>
                  <br/><br/><br/>
                </div>

                <div class="row" v-if="this.isParents == true">
                  <div class="col-md-6">
                    <b-form-group label="Item Type (*)" label-for="item_type">
                      <b-form-select id="item_type" v-model="form.item_type" :options="itemTypeOptions">
                      </b-form-select>
                    </b-form-group>
                  </div>
                  <div class="col-md-6">
                    <b-form-group label="Parents Item (*)">
                      <multiselect v-model="form.parent_item" label="name" track-by="name" open-direction="bottom"
                        :placeholder="`Select ` + form.item_type.toLowerCase()" :options="parentSearchOptions"
                        :searchable="true" :loading="isParentSearch" :close-on-select="true" :options-limit="10"
                        :multiple="false" :allow-empty="false" @search-change="parentFind" @select="parentSelected">
                      </multiselect>
                    </b-form-group>
                  </div>
                </div>

                <div class=" row">
                  <div class="col-md-6">
                    <b-form-group id="input-display" label="Display Name (*)" label-for="display">
                      <b-form-input id="display" v-model="form.display_name" type="text" placeholder="Enter Display Name"
                        required ></b-form-input>
                    </b-form-group>
                  </div>
                  <div class="col-md-6">
                    <b-form-group id="input-code" label="Edition Code (*)" label-for="code">
                      <b-form-input id="code" v-model="form.edition_code" type="text" required placeholder="Enter Edition Code"></b-form-input>
                    </b-form-group>
                </div>

              </div>    
                <div  v-if="this.isParents == false">
                  <div class="row">
                    <div class="col-md-6">
                      <b-form-group label="Categories (*)">
                        <multiselect
                            placeholder="Enter Categories"
                            open-direction="bottom"
                            v-model="form.default_categories"
                            label="name"
                            track-by="name"
                            :options="categoriesSearchOptions"
                            :loading="isCategoriesSearch"
                            :searchable="true"
                            :close-on-select="true"
                            :options-limit="10"
                            :multiple="true"
                            @search-change="categoriesFind">
                          </multiselect>
                      </b-form-group>
                    </div>
                    <div class="col-md-6">
                      <b-form-group label="Vendor (*)">
                      <multiselect v-model='form.vendor_id' label='name' track-by='name'
                      placeholder='Enter Publisher' open-direction='bottom' :options='vendorSearchOptions'
                      :searchable='true' :loading='isVendorSearch' :close-on-select='true' 
                      @search-change='getVendorFind'>
                    </multiselect>
                    </b-form-group>
                  </div>
                </div>
              
            </div>
            
            <div class="row">
              <div class="col-md-6" v-if="this.isParents == false">
                <b-form-group label="Author (*)" >
                  <multiselect v-model="form.author" label="name" track-by="name" placeholder="Enter Author"
                  open-direction="bottom" :options="authorSearchOptions" :searchable="true" :loading="isAuthorSearch"
                  :close-on-select="true" :options-limit="10" :multiple="true" @search-change="authorFind"
                  >
                </multiselect>
                </b-form-group>
              </div>
              <div class="col-md-6">
                <b-form-group label="Narrator (*)" >
                    <multiselect v-model="form.narrator" label="name" track-by="name" placeholder="Enter Narrator"
                    open-direction="bottom" :options="narratorSearchOptions" :searchable="true" :loading="isNarratorSearch"
                    :close-on-select="true" :options-limit="10" :multiple="true" @search-change="narratorFind" required
                    >
                  </multiselect>
                </b-form-group>
              </div>
            </div>
            
            <div class= "row" v-if="this.isParents == false">
              <div class="col-md-6">
                <b-form-group label="Countries (*)">
                  <multiselect placeholder="Enter Countries" open-direction="bottom" v-model="form.countries" label="name"
                    track-by="name" :custom-label="nameWithISO" :options="countriesSearchOptions" :loading="isCountriesSearch"
                    :searchable="true" :close-on-select="true" :options-limit="10" :multiple="true" required>
                  </multiselect>
                </b-form-group>
              </div>

              <div class="col-md-6">
                <b-form-group label="Language (*)">
                  <multiselect placeholder="Enter languages" open-direction="bottom" v-model="form.languages" label="name"
                    track-by="name" :custom-label="nameWithISO" :options="languagesSearchOptions" :searchable="true"
                    :close-on-select="true" :options-limit="10" :multiple="true" required>
                  </multiselect>
                </b-form-group>
              </div>
            </div>
            <div class = "row">
              <div class="col-md-6 ">
                  <b-form-group label="Release Date (*)">
                    <b-form-input type="datetime-local" v-model="form.release_date" required></b-form-input>
                  </b-form-group>
                </div>
                <div class="col-md-6">
                  <b-form-group label="Release Schedule (*)">
                    <b-form-input type="datetime-local" v-model="form.schedule_date" required></b-form-input>
                  </b-form-group>
                </div>
              </div>
            
              <div class="row">
                <div class="col-md-12">
                  <b-form-group label='Description'>
                    <b-form-textarea id="description" v-model="form.description" placeholder="Enter Description Here..." rows="3" max-rows="6">
                    </b-form-textarea>
                  </b-form-group>
                </div>
              </div>
              <div class="col-md-12">
                <b-form-group>
                  <b-form-checkbox v-model="form.is_active" :value="true" :unchecked-value="false">Active
                  </b-form-checkbox>
                </b-form-group>
              </div>
                
              <div class="row">
                <div class="col">
                  <b-button class="mr-3 text-white" type="submit" variant="success">Add Audio</b-button>
                  <b-button class="text-white" variant="secondary" type="reset">Reset</b-button>
                </div>
              </div>
            </div>
          </div>
        </b-form>
      </div>
      
    </div>
  </b-overlay>
</template>

<script>
import moment from "moment";
import { mapState, mapActions } from "vuex";
import constant from "../../store/constant";
export default {
  name: "newAudioBook",
  data() {
    return {
      form: {
        item_type: 'BOOK',
        parent_item: null,
        display_name: null,
        edition_code: null,
        release_date: moment().format('YYYY-MM-DD hh:mm'),
        schedule_date: moment().format('YYYY-MM-DD hh:mm'),
        default_categories:[],
        languages: [{ iso: 'IND', name: 'Indonesia' }],
        countries: [{ iso: 'ID', name: 'Indonesia' }],
        vendor_id:null,
        description:null,
        is_active:true,
        author: [],
        narrator:[],
      },
      itemTypeOptions: {BOOK:constant.ITEM_TYPES.BOOK,NEWSPAPER:constant.ITEM_TYPES.NEWSPAPER,MAGAZINE:constant.ITEM_TYPES.MAGAZINE},
      authorSearchOptions: [],
      narratorSearchOptions:[],
      parentSearchOptions: [],
      categoriesSearchOptions: [],
      vendorSearchOptions:[],
      isAuthorSearch: false,
      isNarratorSearch: false,
      isParentSearch: false,
      isCategoriesSearch: false,
      isVendorSearch:false,
      isCountriesSearch: false,
      isParents: true,
      show: true,
      exist_audiobook: [],
      countriesSearchOptions: [],
      languagesSearchOptions: Object.entries(constant.LANGUAGE_CHOICES).map((item) => ({
        iso: item[0].toUpperCase(),
        name: item[1]
      })),
    };
  },
  computed: {
    ...mapState({
      isError: (state) => state.audioBooks.isError,
      isLoading: (state) => state.audioBooks.isLoading,
      errorMessage: (state) => state.audioBooks.errorMessage,
      successMessage: (state) => state.audioBooks.successMessage,
      items: (state) => state.audioBooks.items,
      item: (state) => state.audioBooks.item,
    }),
  },
  created() {
    this.setCountriesOptions();
  },
  watch: {
    errorMessage: function () {
      if (!this.errorMessage) return;
      return this.baseAlert({
          icon:'error',
          title: 'Error',
          text: this.errorMessage,
        });
    },
    successMessage: function () {
      if (!this.successMessage) return;
      this.messageAlert('success', this.successMessage);
      this.$router.push({
        path: `/audio/editChapter/${this.item.id}`,
      });
    },
  },
  methods: {
    ...mapActions("audioBooks", [
      "postNewAudioBook",
      "searchParents",
      "searchChild",
      "searchAuthorByItemsId",
    ]),
    ...mapActions("narrator", ["fetch"]),
    ...mapActions("author", ["searchAuthor"]),
    ...mapActions("brands", ["searchCategoryQuery", "createBrands", "fetchBrandsByid"]),
    ...mapActions('vendors', ['searchVendors']),
    ...mapActions('items', ['searchCategories',  'getCountries','generateEditionCodeBookMagazine']),
   async onSubmit(event) {
      event.preventDefault();
      const data = this.form;
      if(data.narrator.length==0) {
        return this.baseAlert({
          icon:'error',
          title: 'Error',
          text: 'Narrator Must Be Inputed',
        });
      }
      const payload = {
        name: data.display_name,
        edition_code : data.edition_code,
        release_date: data.release_date,
        release_schedule: data.schedule_date,
        narrator_id: data.narrator.map((item) => item.id),
        description:data.description, 
        is_active: new Boolean(data.is_active),
      }
      if(this.isParents) {
        if(!data.parent_item.id) {
          return this.baseAlert({
            icon:'error',
            title: 'Error',
            text: 'Parent Must Be Inputed',
          });
        }
        if(!data.parent_item.brand_id) {
          return this.baseAlert({
            icon:'error',
            title: 'Error',
            text: 'Brand Parent Must Be Inputed',
          });
        }
        payload.parent_item_id = data.parent_item.id ;
      } 
      else {
        if(!data.vendor_id) {
          return this.baseAlert({
            icon:'error',
            title: 'Error',
            text: 'Vendor Must Be Input',
          });
        }
        if (!data.default_categories) {
          return this.baseAlert({
            icon:'error',
            title: 'Error',
            text: 'Categories Must Be Input',
          });
        }
        payload.author_id = data.author.map((item) => item.id),
        payload.countries = data.countries.map(item => item.iso.toLowerCase()),
        payload.languages = data.languages.map(item => item.iso.toLowerCase()),
        payload.vendor_id = data.vendor_id.id;
        payload.default_categories= data.default_categories.map(item => item.id); // for insert in brands
      }
      this.postNewAudioBook(payload);
    },
    baseAlert(data) {
        this.$swal({
          icon: data.icon,
          title: data.title,
          text: data.text,
        })
    },
    isParentsChange() {
      this.form.display_name = null;
      this.form.parent_item = null;
      this.form.edition_code = null;
      this.form.author = null;
      this.form.narrator = null;
      this.form.release_date = null;
      this.form.schedule_date = null;
      this.form.description = null;
      this.form.languages =  [{ iso: 'IND', name: 'Indonesia' }];
      this.form.countries =  [{ iso: 'ID', name: 'Indonesia' }];
    },
    setCountriesOptions() {
      this.isCountriesSearch = true;
      this.getCountries()
        .then((response) => {
          this.countriesSearchOptions = response.data.data.rows;
          this.isCountriesSearch = false;
        })
        .catch(() => {
          this.isCountriesSearch = false;
      });
    },
    categoriesFind(query) {
      if (!query) return;
      this.isCategoriesSearch = true;
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        this.searchCategories(query)
        .then((response) => {
          this.categoriesSearchOptions = response.data.data.rows;
          this.isCategoriesSearch = false;
        }).catch(() => {
          this.isCategoriesSearch = false;
        });
      }, 600);
    },
    getVendorFind(query){
      this.isVendorSearch = true;
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.searchVendors({ q: query }).then((response) => {
          this.vendorSearchOptions = response.data.data.rows;
          this.isVendorSearch = false;
        }).catch(() => {
          this.isVendorSearch = false;
        });
      }, 600);
    },
    parentFind(data) {
      const payload = {
        type: this.form.item_type.toLowerCase(),
        name: data,
        limit: 20,
      }
      const check = this.parentSearchOptions.find(item => item.name.toLowerCase().includes(data.toLowerCase()));
      if (!check) {
        this.isParentSearch = true;
        this.searchParents(payload)
          .then((response) => {
            this.parentSearchOptions = response.data.data.rows;
            this.isParentSearch = false;
          })
          .catch(() => {
            this.isParentSearch = false;
        });
      }
    },
    async setExist (rows) {
      this.exist_audiobook = (rows ?? []).map((item, idx) => {
        return {
          'No': idx + 1,
          'name': item.name,
          'Release Date': moment(item.release_date),
          'Edition Code': item.edition_code,
          'Total Chapter': '-',
          'author': item.authors[0]?.name,
        }
      });
    },
    async parentSelected(data) {
      this.isParentSearch = false;
      this.form.display_name = `[AUDIO] ${data.name}`;
      this.form.edition_code = `AUT_${data.edition_code}`;
      this.form.edition_code = `AUT_${data.edition_code}`;
    },

    authorFind(query) {
      if (!query) return;

      this.isAuthorSearch = true;
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        let payload = {
          name: query,
          limit: 10,
        };
        this.searchAuthor(payload).then((response) => {
          this.authorSearchOptions = response.data.data.rows;
          this.isAuthorSearch = false;
        }).catch(() => {
          this.isAuthorSearch = false;
        });
      }, 600);
    },
    narratorFind(query) {
      if (!query) return;
      this.isNarratorSearch = true;
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        let payload = {
          query,
          limit: 10,
        };
        this.fetch(payload).then((response) => {
          this.narratorSearchOptions = response.data.data.rows;
          this.isNarratorSearch = false;
        }).catch(() => {
          this.isNarratorSearch = false;
        });
      }, 600);
    },
    messageAlert(icon, title, timer = 3000) {
      this.$swal({
        toast: "true",
        position: "top-end",
        icon,
        title,
        showConfirmButton: false,
        timer,
        timerProgressBar: true,
      });
    },
    nameWithISO({ name, iso }) {
      return `${iso} - ${name}`
    },
    reset() {
      Object.assign(this.$data, this.$options.data.call(this));
    }
  }
};
</script>

<style lang="css">
.create-new {
  font-family: "Avenir Next", "effra", Helvetica, Arial, sans-serif;
}
</style>
